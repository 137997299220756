<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.EXP.SUB_EXPENSES_MATERIALS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['subcontractors-invoices-materials-create']"
            to="add"
            class="btn btn-light-success font-weight-bolder"
            ><i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col class="pb-0" :md="6">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-to-input"
                  type="text"
                  name="date_from"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
                  :validateState="{}"
                  v-model="filters.dateFrom"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="6">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-from-input"
                  type="text"
                  name="date_to"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
                  :validateState="{}"
                  v-model="filters.dateTo"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group
                id="subcontractors-group"
                label-for="subcontractors-input"
              >
                <FormSelect
                  id="subcontractors-input"
                  :placeholder="$t('FORM_LABELS.COMPANY')"
                  type="select"
                  name="subcontractors"
                  :options="subcontractors"
                  clearable="true"
                  v-model="filters.subcontractorId"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group
                id="subcontractors-group"
                label-for="subcontractors-input"
              >
                <FormSelect
                  id="subcontractor-types-input"
                  :placeholder="$t('FORM_LABELS.TYPE')"
                  type="select"
                  name="subcontractor-types"
                  :options="subcontractorTypes"
                  clearable="true"
                  v-model="filters.subcontractorTypeId"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group id="invoices-group" label-for="invoices-input">
                <FormSelect
                  id="invoice-filter-input"
                  type="select"
                  :placeholder="$t('FORM_LABELS.INVOICE_FILTER')"
                  name="invoice-filter"
                  :options="[
                    { name: 'Само фактурирани', id: 1 },
                    { name: 'Само нефактуриран', id: 0 },
                  ]"
                  clearable="true"
                  v-model="filters.invoiceFilter"
                ></FormSelect>
              </b-form-group>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingSucontractorExpense"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
          }"
          @update:options="getDataFromApi()"
          class="elevation-1 px-6"
        >
          <template v-slot:[`item.invoiceDate`]="{ item }">
            {{ item.invoiceDate | moment("DD.MM.YYYY") }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-end">
              <span v-if="checkIfUserHasPermission(['protocols-update'])">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      color="warning"
                      fab
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="handlePrintClick(item.id)"
                    >
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("LABELS.PRINT") }}</span>
                </v-tooltip>
              </span>
              <router-link
                v-if="
                  checkIfUserHasPermission(['subcontractors-invoices-materials-update'])
                "
                :to="{
                  name: 'edit-subcontractor-expenses-materials',
                  params: { id: item.id },
                }"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("LABELS.EDIT") }}</span>
                </v-tooltip>
              </router-link>
              <template v-else>Няма права</template>
            </div>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import SearchTools from "@/core/components/table/SearchTools.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";

import { FETCH_SUBCONTRACTORS } from "@/modules/subcontractors/store/subcontractor.module";
import {
  FETCH_SUBCONTRACTORS_EXPENSES_MATERIALS,
  GENERATE_MATERIAL_PDF_URL,
} from "@/modules/subcontractor-expenses-materials/store/subcontractor-expenses-materials.module.js";
import { FETCH_SUBCONTRACTORS_TYPES } from "@/modules/subcontractors-types/store/subcontractor-type.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  name: "SubcontractorExpensesMaterialsList",
  mixins: [permissionMixin],
  components: {
    SearchTools,
    FormDatepicker,
    FormSelect,
  },
  data() {
    return {
      isPageLoading: true,
      items: [],
      totalItems: 100,
      searchQuery: "",
      options: {
        sortBy: ["invoiceDate"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 30,
      },
      filters: {
        dateFrom: null,
        dateTo: null,
        subcontractorId: null,
        subcontractorTypeId: null,
        invoiceFilter: null,
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      subcontractors: [],
      subcontractorTypes: [],
      headers: [
        { text: this.$i18n.t("FORM_LABELS.DATE"), value: "invoiceDate" },
        {
          text: this.$i18n.t("FORM_LABELS.COMPANY"),
          value: "subcontractorName",
        },
        {
          text: this.$i18n.t("FORM_LABELS.TYPE"),
          value: "subcontractorType",
        },
        {
          text: this.$i18n.t("FORM_LABELS.INVOICE_NUMBER"),
          value: "invoiceNumber",
        },
        {
          text: this.$i18n.t("FORM_LABELS.VALUE_WITH_VAT"),
          value: "totalWithVat",
        },
        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.EXP.SUB_EXPENSES_MATERIALS"),
        route: { name: "list-subcontractor-expenses-materials" },
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") },
    ]);
    this.$store
      .dispatch(
        FETCH_SUBCONTRACTORS,
        this.$url.transformParams({
          onlySelectValues: true,
          selectValueKey: "company_name",
          onlyMaterial: true,
        })
      )
      .then((data) => {
        this.subcontractors = data.data.items;
      });

    this.$store.dispatch(FETCH_SUBCONTRACTORS_TYPES).then((data) => {
      this.subcontractorTypes = data.data.items.filter((t) => t.id !== 1);
    });
  },
  computed: {
    ...mapGetters(["isLoadingSucontractorExpense"]),
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery,
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    },
  },
  methods: {
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      Object.keys(vm.filters).forEach((key) => {
        vm.filters[key] = null;
      });
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      // console.log(this.params);
      let apiParams = vm.$url.transformParams(params);
      // console.log(apiParams);
      vm.$store
        .dispatch(FETCH_SUBCONTRACTORS_EXPENSES_MATERIALS, apiParams)
        .then((data) => {
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found",
            });
            this.$router.push({ name: "list-subcontractor-expenses" });
          }
        });
    },
    handlePrintClick(id) {
      this.$store.dispatch(GENERATE_MATERIAL_PDF_URL, id);
    },
  },
};
</script>
